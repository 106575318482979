import VueRouter from 'vue-router';
import Vue from 'vue';
import ManageRoute from '../manage/routes';
import Store from '../../store/index';

Vue.use(VueRouter);

const routes = [
    {
        name: 'main',
        path: '/',
        redirect: { name: 'map' },
        component: () => import('./views/Dashboard'),
        meta: {
            template: 'MainTemplate',
        },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('./views/Dashboard'),
        meta: {
            template: 'MainTemplate',
        },
    },
    ManageRoute,
    {
        path: '/users',
        name: 'usersPanel',
        component: () => import('./views/UserPanel'),
        meta: {
            template: 'MainTemplate',
        },
        children: [
            {
                name: 'users',
                path: '',
                component: () => import('./views/UserPanel/UsersList'),
                meta: {
                    template: 'MainTemplate',
                },
            },
            {
                name: 'userPanel',
                path: ':userId',
                component: () => import('./views/UserPanel/UserData'),
                meta: {
                    template: 'MainTemplate',
                },
            },
        ],
    },
    {
        name: 'reports',
        path: '/reports',
        component: () => import('./views/Reports'),
        meta: {
            template: 'MainTemplate',
        },
    },
    {
        name: 'failures',
        path: '/failures',
        component: () => import('./views/Failures'),
        meta: {
            template: 'MainTemplate',
        },
    },
    {
        name: 'maintenance',
        path: '/maintenance',
        component: () => import('./views/Maintenance'),
        meta: {
            template: 'MainTemplate',
        },
    },
    {
        name: 'inspections',
        path: '/inspections',
        component: () => import('./views/Inspections'),
        meta: {
            template: 'MainTemplate',
        },
    },
    {
        name: 'map',
        path: '/map',
        component: () => import('../map/View'),
        meta: {
            public: true,
            template: 'BareTemplate',
            title: 'UGIS – mapa',
        },
        children: [
            {
                name: 'map-id',
                path: ':id',
                component: () => import('../map/View'),
                meta: {
                    template: 'BareTemplate',
                    title: 'UGIS – mapa',
                },
            },
        ],
    },
    {
        name: '404',
        path: '*',
        component: () => import('../../components/errors/404'),
        meta: {
            template: 'MainTemplate',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const user = Store.getters['app/user'];
    if (user.id == null) {
        if (!(to.meta.public ?? false)) {
            next({ name: 'map' });
        }
    }
    next();
});

router.afterEach((to, from) => {
    let title = 'UGIS';
    if (to.meta.title) {
        title = to.meta.title;
    }
    window.document.title = title;
});

export default router;
